




























import { Component, Vue } from "vue-property-decorator";

@Component
export default class SidebarFinance extends Vue {
  public data = [
    {
      item: "Journals",
      visibility: false,
      collapse: "collapse-1",
      child: [
        { item: "Journal Entries", route: "/journal/entry" },
        { item: "Journal Entries [Complex]", route: "/journal/complex-entry" },
        { item: "Book Incomes or Receivable", route: "/journal/book-income" },
        { item: "Book Expense or Payable", route: "/journal/book-expense" },
      ],
    },
    {
      item: "Receipts",
      visibility: false,
      collapse: "collapse-2",
      child: [
        { item: "Cash Receipts", route: "/receipt/cashreceipts" },
        { item: "Bank Receipts", route: "/receipt/bankreceipts" },
        {
          item: "Receipts From Parties",
          route: "/receipt/receipt-from-parties",
        },
      ],
    },
    {
      item: "Payments",
      visibility: false,
      collapse: "collapse-3",
      child: [
        { item: "Cash Payments", route: "/payments/cash-payments" },
        { item: "Bank Payments", route: "/payments/bank-payments" },
        { item: "Cash Transfers", route: "/payments/cash-transfer" },
      ],
    },
    {
      item: "Banking",
      visibility: false,
      collapse: "collapse-4",
      child: [
        {
          item: "Cash Deposit & Withdrawals",
          route: "/banking/cash-desposit-withdrawals",
        },
        {
          item: "Inter Bank Fund Transfer",
          route: "/banking/inter-bank-funds-transfer",
        },
        { item: "Bank Reconciliation", route: "/banking/bank-reconciliation" },
        {
          item: "Issued Cheques Bounce",
          route: "/banking/issued-cheques-bounce",
        },
        {
          item: "Received Cheques Bounce",
          route: "/banking/received-cheques-bounce",
        },
      ],
    },
    {
      item: "Party Adjustments",
      visibility: false,
      collapse: "collapse-5",
      child: [
        { item: "Debit Notes ", route: "/party-adjustments/debit-notes" },
        { item: "Credit Notes", route: "/party-adjustments/credit-notes" },
        {
          item: "Adjust Party Bills",
          route: "/party-adjustments/adjust-party-bills",
        },
      ],
    },
    {
      item: "Opening Balances",
      visibility: false,
      collapse: "collapse-6",
      child: [
        {
          item: "Opening Balances",
          route: "/opening-balances/opening-balances",
        },
        {
          item: "Party Opening Balances",
          route: "/opening-balances/party-opening-balances",
        },
      ],
    },

    {
      item: "VATCST",
      visibility: false,
      collapse: "collapse-7",
      child: [
        { item: "VAT Opening", route: "/vatcst/vat-openings" },
        { item: "VAT Payments", route: "/vatcst/vat-payments" },
        { item: "VAT Month End", route: "/vatcst/vat-month-end" },
      ],
    },
  ];

  mounted() {
    let index = this.data.findIndex((x) => {
      return x.item === this.$route.name;
    });
    if (index >= 0) {
      this.data[index]["visibility"] = true;
    }    
  }
}
