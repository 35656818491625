import http from "../common-http";

class AccountService {
  getAll() {
    return http.get("/finance/get-account/");
  }

  getAccountGroup() {
    return http.get("/finance/account-group/");
  }

  getByTitle(title: any) {
    return http.get(`/finance/get-account-node/?title=${title}`);
  }
  
  getByTitleMaster(title: any) {
    return http.get(`/finance/get-account-master/?title=${title}`);
  }

  getAccounts() {
    return http.get(`/finance/get-account-node/`);
  }

  getTransaction(param: any) {
    return http.post(`/finance/create-transaction-jv/`, param);
  }

  getByParent(parent: any) {
    return http.get(`/finance/get-account/?parent=${parent}`);
  }
  
  createAccount(data: any) {
    return http.post("/finance/create-account/", data);
  }

  updateAccount(data: any) {
    return http.post("/finance/update-account/", data);
  }

  getchild(id: string) {
    return http.get(`/accounts/get-child/${id}`); 
  }

  getchartchildren() {
    return http.get(`/accounts/get-children/`); 
  }

  get(id: string) {
    return http.get(`/accounts/chart/${id}`); 
  }

  

  update(id: string, data: any) {
    return http.put(`/accounts/chart/${id}/`, data);
  }

  delete(id: string) {
    return http.delete(`/accounts/chart/${id}`);
  }

  deleteAll() {
    return http.delete(`/accounts/chart/`);
  }

  findByTitle(title: string) {
    return http.get(`/accounts/chart/?title=${title}`);
  }
}


export default new AccountService();